import {
  SET_CURRENT_LOUNGE_ROOM_ID,
  SET_CURRENT_LOUNGE_ROOM_SPECTATING,
} from "~~/redux/actionList";

/** Data associated with the lounge */
const initialState = {
  // The ID of the lounge room the user is currently in
  currentLoungeRoomId: null,
};

export default (state = initialState, action) => {
  const { id, spectating } = action;

  switch (action.type) {
    case SET_CURRENT_LOUNGE_ROOM_ID:
      return { ...state, currentLoungeRoomId: id };
    case SET_CURRENT_LOUNGE_ROOM_SPECTATING:
      return { ...state, spectating };
    default:
      return state;
  }
};
