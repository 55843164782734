let featureFlags = {};

export function setFeatureFlags(flags) {
  featureFlags = flags;
}

export function getFeatureFlag(name) {
  return featureFlags[name];
}

export function getFeatureFlags(...flags) {
  if (flags.length === 0) {
    return featureFlags;
  }
  return flags.map((f) => getFeatureFlag(f));
}

/**
 * Lets us use FeatureFlags.get() as the API for this utility.
 *
 * @param {string} flag Name of the feature flag
 */
export function get(flag) {
  return getFeatureFlag(flag);
}
