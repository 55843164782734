/**
 * @fileoverview state for the stage
 */
import omit from "lodash/omit";
import {
  ADD_STAGE_QUESTION,
  ARCHIVE_STAGE_QUESTIONS,
  DELETE_STAGE_QUESTION,
  DOWNVOTE_STAGE_QUESTION,
  SET_STAGE_QUESTIONS,
  UPDATE_STAGE_QUESTION,
  UPDATE_STAGE_QUESTION_STATUS,
  UPVOTE_STAGE_QUESTION,
} from "~~/redux/actionList";

// Mapping of questionId => stageQuestion
const initialState = {};

export default (state = initialState, action) => {
  const { stageQuestions, stageQuestion, questionId, status } = action;

  switch (action.type) {
    case SET_STAGE_QUESTIONS:
      // FIXME: stageQuestions shouldn't be null here but sometimes it is, just fall back for now
      return stageQuestions || state;
    case ADD_STAGE_QUESTION:
      return { ...state, [stageQuestion.id]: stageQuestion };
    case UPDATE_STAGE_QUESTION:
      return { ...state, [stageQuestion.id]: stageQuestion };
    case DELETE_STAGE_QUESTION:
      return omit(state, stageQuestion.id);
    case DOWNVOTE_STAGE_QUESTION:
      return { ...state, [stageQuestion.id]: stageQuestion };
    case UPVOTE_STAGE_QUESTION:
      return { ...state, [stageQuestion.id]: stageQuestion };
    case ARCHIVE_STAGE_QUESTIONS:
      return {};
    case UPDATE_STAGE_QUESTION_STATUS:
      return { ...state, [questionId]: { ...state[questionId], status } };
    default:
      return state;
  }
};
