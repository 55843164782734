import omit from "lodash/omit";
import {
  ARCHIVE_POLL,
  CREATE_POLL,
  DELETE_POLL,
  SEARCH_FOR_POLLS,
  SET_EDITING_POLL,
  SET_MOST_RECENTLY_OPENED_POLL,
  SET_POLLS,
  START_POLL_SEARCH,
  STOP_POLL_SEARCH,
  UPDATE_POLL,
  VOTE_FOR_POLL,
} from "~~/redux/actionList";

const initialState = {
  allHashids: [],
  byHashid: {},
  editingPoll: null,
  mostRecentlyOpenedPoll: null,
  tempIdsForSearch: [],
};

export default (state = initialState, action) => {
  const { type, payload: { poll, polls, pollHashid, searchText } = {} } =
    action;
  switch (type) {
    // From Redux point of view, Archive/Delete are just removals from state, but
    // we're keeping these actions separate to enable more intuitive debugging as
    // the code paths for each are different.
    // We may also enable UI for viewing archived polls, which is easily extensible here.
    case ARCHIVE_POLL:
    case DELETE_POLL:
      return {
        ...state,
        allHashids: state.allHashids.filter((hashid) => hashid !== pollHashid),
        byHashid: omit(state.byHashid, pollHashid),
      };
    case CREATE_POLL:
      return {
        ...state,
        allHashids: Array.from(new Set([poll.hashid, ...state.allHashids])),
        byHashid: { ...state.byHashid, [poll.hashid]: poll },
      };
    case SET_EDITING_POLL:
      return { ...state, editingPoll: pollHashid };
    case SET_POLLS:
      return {
        ...state,
        allHashids: polls.map((_poll) => _poll.hashid),
        byHashid: polls.reduce(
          (obj, _poll) => ({ ...obj, [_poll.hashid]: _poll }),
          {}
        ),
      };
    case UPDATE_POLL:
      return {
        ...state,
        byHashid: {
          ...state.byHashid,
          [poll.hashid]: {
            ...state.byHashid[poll.hashid],
            ...poll,
          },
        },
        allHashids: state.allHashids.some((hashid) => hashid === poll.hashid)
          ? state.allHashids
          : [...state.allHashids, poll.hashid],
      };
    case VOTE_FOR_POLL:
      return {
        ...state,
        byHashid: {
          ...state.byHashid,
          [poll.hashid]: poll,
        },
      };
    case SEARCH_FOR_POLLS:
      return {
        ...state,
        searchText,
        allHashids: Object.keys(state.byHashid).reduce(
          (filteredIds, hashid) => {
            const { question, pollOptions } = state.byHashid[hashid];
            if (
              question.toLowerCase().includes(searchText?.toLowerCase()) ||
              pollOptions.some(({ optionText }) =>
                optionText.toLowerCase().includes(searchText?.toLowerCase())
              )
            ) {
              filteredIds.push(hashid);
            }
            return filteredIds;
          },
          []
        ),
      };
    case START_POLL_SEARCH:
      return {
        ...state,
        tempIdsForSearch: state.allHashids,
      };
    case STOP_POLL_SEARCH:
      return {
        ...state,
        searchText: "",
        allHashids: state.tempIdsForSearch,
        tempIdsForSearch: [],
      };
    case SET_MOST_RECENTLY_OPENED_POLL:
      return {
        ...state,
        mostRecentlyOpenedPoll: pollHashid,
      };
    default:
      return state;
  }
};
