import PANEL_NOTIFICATION_TYPE from "~~/constants/panelNotificationType";
import {
  SET_CHAT_CHANNEL_NOTIFICATION,
  SET_PEOPLE_NOTIFICATION,
  SET_POLL_NOTIFICATION,
  SET_QNA_NOTIFICATION,
} from "~~/redux/actionList";

/** Mapping of channel => panelNotificationType */
/*
 * Probably should be made more robust, but for now we're doing simple mappings
 * like "public" => true, "green-room" => true, etc.
 */
const initialState = {
  chat: {},
  qna: PANEL_NOTIFICATION_TYPE.NONE,
  people: PANEL_NOTIFICATION_TYPE.NONE,
  poll: PANEL_NOTIFICATION_TYPE.NONE,
};

export default function PanelNotifications(state = initialState, action) {
  const { key, panelNotificationType, type } = action;

  switch (type) {
    case SET_CHAT_CHANNEL_NOTIFICATION:
      return {
        ...state,
        chat: {
          ...state.chat,
          [key]: panelNotificationType,
          [`${key}NotificationCount`]:
            panelNotificationType === PANEL_NOTIFICATION_TYPE.LOUD
              ? (state.chat[`${key}NotificationCount`] || 0) + 1
              : 0,
        },
      };
    case SET_QNA_NOTIFICATION:
      return {
        ...state,
        qna: panelNotificationType,
      };
    case SET_PEOPLE_NOTIFICATION:
      return {
        ...state,
        people: panelNotificationType,
      };
    case SET_POLL_NOTIFICATION:
      return {
        ...state,
        poll: panelNotificationType,
      };
    default:
      return state;
  }
}
