/* eslint-disable no-nested-ternary, import/prefer-default-export */
export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
};

export const COLORS = {
  POLL_FG: "#AF352D",
  POLL_BG: "#792D2A",
};

export const HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

// Source of truth for how display elements are rendered on the Stages show page, independent of the user role
// Keeps things consistent for things like a producer being in preview mode, etc.
export const STAGES_SHOW_VIEW_MODE = {
  ATTENDEE: "attendee",
  PRODUCER: "producer",
};

// Because of the way the react-rails gem works, we can't use a Portal that is a direct
// descendant of body like we normally would. This is because all of react's data inherits
// from data attributes on a child div of the body element. So we must keep everything
// including the portal as a child of that div.
export const defaultPortalContainer =
  document.querySelectorAll("[data-react-class]")?.[0] ||
  document.body.children[0];
