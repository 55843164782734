/**
 * @fileoverview state for the stage
 */
import {
  SET_SPEAKER_TIMER,
  SET_STAGE,
  SET_STAGE_STATUS,
} from "~~/redux/actionList";

// Holds data for the current stage. See Stages reducer for data on other stages at same event
// TODO: consider renaming this to CurrentStage to avoid confusion with ^
const initialState = {
  status: null,
  id: null,
  eventId: null,
  startTime: null,
  endTime: null,
  startedTime: null,
  openedTime: null,
  endedTime: null,
  recordingUrl: null,
  speakerTimer: null,
};

export default (state = initialState, action) => {
  const { status, stageState, timer } = action;

  switch (action.type) {
    case SET_STAGE_STATUS:
      return { ...state, status };
    case SET_STAGE:
      return { ...state, ...stageState };
    case SET_SPEAKER_TIMER:
      return { ...state, speakerTimer: timer };
    default:
      return state;
  }
};
