import {
  ADD_BREAKOUT_STREAM,
  CLEAR_BREAKOUT_STREAMS,
  REMOVE_BREAKOUT_STREAM,
} from "~~/redux/actionList";

/** Mapping of streamId => stream */
const initialState = {};

export default (state = initialState, action) => {
  const { stream, streamId } = action;

  switch (action.type) {
    case ADD_BREAKOUT_STREAM:
      return { ...state, [stream.getId()]: stream };
    case REMOVE_BREAKOUT_STREAM:
      /* eslint-disable-next-line no-case-declarations */
      const newStreams = { ...state };
      delete newStreams[streamId];
      return newStreams;
    case CLEAR_BREAKOUT_STREAMS:
      return initialState;
    default:
      return state;
  }
};
