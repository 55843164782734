import { SET_STAGES } from "~~/redux/actionList";

// Holds data for all stages at the event.
// Stage (singular) reducer holds data specifically for the current stage.
const initialState = {};

export default (state = initialState, action) => {
  const { stages } = action;

  switch (action.type) {
    case SET_STAGES:
      return { ...state, ...stages };
    default:
      return state;
  }
};
