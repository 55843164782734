import { LOUNGE_PARTICIPANT_STATE } from "~~/constants/loungeParticipantState";
import {
  ADD_LOUNGE_PRESENCE_FOR_ROOM,
  REMOVE_LOUNGE_PRESENCE_FOR_ROOM,
  SET_LOUNGE_PRESENCE_FOR_ROOM,
  SET_LOUNGE_PRESENCE_FOR_ROOMS,
} from "~~/redux/actionList";

// Deep clone helper without using Immer. If the room does not exist, create a blank
function deepCopyWithDefault(obj, targetRoomId) {
  /* eslint-disable no-param-reassign */
  const dupe = Object.keys(obj).reduce((newObj, roomId) => {
    newObj[roomId] = {
      participant: [...obj[roomId].participant],
      spectator: [...obj[roomId].spectator],
    };
    return newObj;
  }, {});

  if (!dupe[targetRoomId]) {
    dupe[targetRoomId] = {
      participant: [],
      spectator: [],
    };
  }
  return dupe;
}

/** Mapping of loungeRoomId => { participant/spectator: array of eventRegistrationIds } */
const initialState = {};

export default (state = initialState, action) => {
  const { presenceHash, room, roomId, er, userStatus } = action;

  switch (action.type) {
    case SET_LOUNGE_PRESENCE_FOR_ROOMS:
      // Overwrite the original state in case rooms are removed
      return { ...presenceHash };
    case SET_LOUNGE_PRESENCE_FOR_ROOM:
      return { ...state, [room.id]: room.presence };
    case ADD_LOUNGE_PRESENCE_FOR_ROOM: {
      const newState = deepCopyWithDefault(state, roomId);
      if (userStatus === LOUNGE_PARTICIPANT_STATE.PARTICIPANT) {
        // Remove from spectator if there
        newState[roomId].spectator = newState[roomId].spectator.filter(
          (x) => x !== er.id.toString()
        );
        // Add to participant if needed
        if (!newState[roomId].participant.includes(er.id.toString())) {
          newState[roomId].participant.push(er.id.toString());
        }
      } else {
        // spectator
        // Remove from participant if there
        newState[roomId].participant = newState[roomId].participant.filter(
          (x) => x !== er.id.toString()
        );
        // Add to spectator if needed
        if (!newState[roomId].spectator.includes(er.id.toString())) {
          newState[roomId].spectator.push(er.id.toString());
        }
      }
      return newState;
    }
    case REMOVE_LOUNGE_PRESENCE_FOR_ROOM: {
      const newState = deepCopyWithDefault(state, roomId);
      newState[roomId].spectator = newState[roomId].spectator.filter(
        (x) => x !== er.id.toString()
      );
      newState[roomId].participant = newState[roomId].participant.filter(
        (x) => x !== er.id.toString()
      );
      return newState;
    }
    default:
      return state;
  }
};
