import { STAGES_SHOW_VIEW_MODE } from "~~/constants";
import { SET_CHAT_HIDDEN, SET_VIEW_MODE } from "~~/redux/actionList";

/** Various UI configurations */
const initialState = {
  chatHidden: false,
  // Source of truth for which display elements to show, independent of the user role
  // Keeps things consistent for things like a producer being in preview mode, etc.
  viewMode: STAGES_SHOW_VIEW_MODE.ATTENDEE,
};

export default (state = initialState, action) => {
  const { chatHidden, viewMode } = action;

  switch (action.type) {
    case SET_VIEW_MODE:
      return { ...state, viewMode };
    case SET_CHAT_HIDDEN:
      return { ...state, chatHidden };
    default:
      return state;
  }
};
