import {
  REMOVE_BREAKOUT_ROOM,
  SET_BREAKOUT_ROOM,
  UPDATE_BREAKOUT_ROOM,
} from "~~/redux/actionList";

/** The current user's breakout room */
const initialState = null;

export default (state = initialState, action) => {
  const { room } = action;

  switch (action.type) {
    case SET_BREAKOUT_ROOM:
      return room;
    case UPDATE_BREAKOUT_ROOM:
      return { ...state, ...room };
    case REMOVE_BREAKOUT_ROOM:
      return initialState;
    default:
      return state;
  }
};
