import {
  ADD_PHOTOBOOTH_TEMPLATES,
  DELETE_PHOTOBOOTH_TEMPLATE,
  RENAME_PHOTOBOOTH_TEMPLATE,
  SET_PHOTOBOOTH_TEMPLATES,
} from "~~/redux/actionList";
import { toObject } from "~~/utils/arrayUtils";

/** Mapping of templateId => template obj */
const initialState = {};

export default (state = initialState, action) => {
  const { templates, template, name } = action;

  switch (action.type) {
    case ADD_PHOTOBOOTH_TEMPLATES:
      return { ...state, ...templates };
    case SET_PHOTOBOOTH_TEMPLATES:
      return toObject(templates, "id");
    case RENAME_PHOTOBOOTH_TEMPLATE:
      return { ...state, [template.id]: { ...state[template.id], name } };
    case DELETE_PHOTOBOOTH_TEMPLATE:
      /* eslint-disable-next-line no-case-declarations */
      const newState = { ...state };
      delete newState[template.id];
      return newState;
    default:
      return state;
  }
};
