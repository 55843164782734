export function canAutoRegister(event, user) {
  return Boolean(
    !event.welcomeRegRequired && user.id && user.firstName && user.lastName
  );
}

export function getInitials(reg) {
  if (!reg) {
    return "";
  }
  const firstInitial = reg.firstName ? reg.firstName.charAt(0) : "";
  const lastInitial = reg.lastName ? reg.lastName.charAt(0) : "";
  return firstInitial.toUpperCase() + lastInitial.toUpperCase();
}

export function getFullName(reg) {
  if (!reg) {
    return "";
  }
  return `${reg.firstName || ""} ${reg.lastName || ""}`;
}

export function getSecondaryText(reg) {
  if (!reg) {
    return "";
  }

  if (reg.company && reg.companyRole) {
    return `${reg.companyRole}, ${reg.company}`;
  }
  if (reg.company) {
    return reg.company;
  }
  if (reg.companyRole) {
    return reg.companyRole;
  }
  return "";
}

export function isModerator(reg) {
  return ["staff", "speaker", "moderator"].includes(reg.registrationType);
}
