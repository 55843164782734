import * as AssertionFailureUI from "~~/wlog/assert/AssertionFailureUI";
import { getNamespace } from "~~/wlog/namespace";

/**
 * Asserts that something has gone wrong.
 *
 * @param {string} namespace The namespace for this assertion failure
 * @param {string | Error} messageOrError An error or error message to be
 * displayed
 */
export function assertionFailure(namespace, messageOrError) {
  if (!getNamespace(namespace)) {
    console.error(`[WLog] Unrecognized namespace ${namespace}`);
  }

  let error;
  if (messageOrError instanceof Error) {
    error = messageOrError;
  } else {
    error = new Error(messageOrError);
  }

  // Report error to UI, console, and Sentry
  if (window.CLIENT_ENV.ASSERTION_UI_ENABLED) {
    AssertionFailureUI.displayError(error);
  }

  console.error(error);
  Sentry?.withScope((scope) => {
    scope.setTag("exception-type", "js-assertion");
    scope.setTag("namespace", namespace);
    scope.setLevel("warning");
    Sentry?.captureException(error);
  });
}

/**
 * Assert that a condition is true.
 *
 * @param {boolean} condition A condition, resolving to true or false
 * @param {string} namespace The namespace for this assertion
 * @param {string} message Message to be displayed on failure
 * @returns {boolean} The result of the condition
 */
export function assert(condition, namespace, message) {
  if (!condition) {
    assertionFailure(namespace, message);
  }

  return condition;
}
