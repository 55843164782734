//  Video Actions
export const UPDATE_AGORA_CONFIG = "UPDATE_AGORA_CONFIG";
export const ADD_STREAM = "ADD_STREAM";
export const REMOVE_STREAM = "REMOVE_STREAM";
export const REMOVE_ALL_STREAMS = "REMOVE_ALL_STREAMS";
export const REMOVE_CLIENT = "REMOVE_CLIENT";
export const REMOVE_LOCAL_STREAM = "REMOVE_LOCAL_STREAM";
export const SET_CLIENT = "SET_CLIENT";
export const SET_STREAM = "SET_STREAM";
export const SET_STAGE_SNAPSHOT = "SET_STAGE_SNAPSHOT";
export const SET_STAGE_SUB_LEVEL = "SET_STAGE_SUB_LEVEL";
export const SET_IS_INJECTION_STREAMING = "SET_IS_INJECTION_STREAMING";
export const SET_STREAM_VIDEO_CONFIG = "SET_STREAM_VIDEO_CONFIG";
export const SET_AGORA_RESOLVED_SCHEME = "SET_AGORA_RESOLVED_SCHEME";

export const ADD_EVENT_ASSETS = "ADD_EVENT_ASSETS";
export const UPDATE_EVENT_ASSET = "UPDATE_EVENT_ASSET";
export const DELETE_EVENT_ASSET = "DELETE_EVENT_ASSET";
export const SET_EVENT_ASSETS = "SET_EVENT_ASSETS";

export const SET_DYNAMIC_STAGEKIT_ASSETS = "SET_DYNAMIC_STAGEKIT_ASSETS";

export const SET_DYNAMIC_EVENT_ASSETS = "SET_DYNAMIC_EVENT_ASSETS";
export const ADD_DYNAMIC_EVENT_ASSETS = "ADD_DYNAMIC_EVENT_ASSETS";
export const DELETE_DYNAMIC_EVENT_ASSET = "DELETE_DYNAMIC_EVENT_ASSET";
export const UPDATE_DYNAMIC_EVENT_ASSET = "UPDATE_DYNAMIC_EVENT_ASSET";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_REGISTRATION = "SET_CURRENT_REGISTRATION";

export const SET_REGISTRATION_TO_BAN = "SET_REGISTRATION_TO_BAN";
export const CLEAR_REGISTRATION_TO_BAN = "CLEAR_REGISTRATION_TO_BAN";
export const LOCAL_BAN_REGISTRATION = "LOCAL_BAN_REGISTRATION";

export const REMOVE_REGISTRATION_BREAKOUT_ROOM_IDS =
  "REMOVE_REGISTRATION_BREAKOUT_ROOM_IDS";
export const REMOVE_REGISTRATION_BREAKOUT_ROOM_ID =
  "REMOVE_REGISTRATION_BREAKOUT_ROOM_ID";
export const ADD_BREAKOUT_STREAM = "ADD_BREAKOUT_STREAM";
export const REMOVE_BREAKOUT_STREAM = "REMOVE_BREAKOUT_STREAM";
export const CLEAR_BREAKOUT_STREAMS = "CLEAR_BREAKOUT_STREAMS";

export const SET_ONLINE_USERS = "SET_ONLINE_USERS";
export const ADD_ONLINE_USER = "ADD_ONLINE_USER";
export const REMOVE_ONLINE_USER = "REMOVE_ONLINE_USER";

export const SET_ONLINE_MOBILE_USERS = "SET_ONLINE_MOBILE_USERS";
export const ADD_ONLINE_MOBILE_USER = "ADD_ONLINE_MOBILE_USER";
export const REMOVE_ONLINE_MOBILE_USER = "REMOVE_ONLINE_MOBILE_USER";

export const SET_CHAT_HIDDEN = "SET_CHAT_HIDDEN";
export const SET_VIEW_MODE = "SET_VIEW_MODE";

export const SET_STAGES = "SET_STAGES";

export const SET_STAGE = "SET_STAGE";
export const SET_STAGE_STATUS = "SET_STAGE_STATUS";

export const SET_EVENT = "SET_EVENT";

export const SET_BREAKOUT_SESSIONS = "SET_BREAKOUT_SESSIONS";
export const REMOVE_SINGLE_BREAKOUT_SESSION = "REMOVE_SINGLE_BREAKOUT_SESSION";
export const ADD_BREAKOUT_SESSION = "ADD_BREAKOUT_SESSION";
export const UPDATE_SINGLE_BREAKOUT_SESSION = "UPDATE_SINGLE_BREAKOUT_SESSION";

export const SET_BREAKOUT_SESSION = "SET_BREAKOUT_SESSION";
export const UPDATE_BREAKOUT_SESSION = "UPDATE_BREAKOUT_SESSION";
export const REMOVE_BREAKOUT_SESSION = "REMOVE_BREAKOUT_SESSION";

export const SET_BREAKOUT_ROOMS = "SET_BREAKOUT_ROOMS";
export const ADD_BREAKOUT_ROOMS = "ADD_BREAKOUT_ROOMS";
export const ADD_BREAKOUT_ROOM = "ADD_BREAKOUT_ROOM";
export const REMOVE_SINGLE_BREAKOUT_ROOM = "REMOVE_SINGLE_BREAKOUT_ROOM";
export const REMOVE_REGISTRATION_FROM_ROOM = "REMOVE_REGISTRATION_FROM_ROOM";

export const UPDATE_BREAKOUT_CONFIG = "UPDATE_BREAKOUT_CONFIG";
export const CLEAR_BREAKOUT_CONFIG = "CLEAR_BREAKOUT_CONFIG";
export const SET_JOIN_IMMEDIATELY = "SET_JOIN_IMMEDIATELY";

export const SET_BREAKOUT_ROOM = "SET_BREAKOUT_ROOM";
export const UPDATE_BREAKOUT_ROOM = "UPDATE_BREAKOUT_ROOM";
export const REMOVE_BREAKOUT_ROOM = "REMOVE_BREAKOUT_ROOM";

export const ADD_REGISTRATIONS = "ADD_REGISTRATIONS";

export const SET_STAGE_QUESTIONS = "SET_STAGE_QUESTIONS";
export const ADD_STAGE_QUESTION = "ADD_STAGE_QUESTION";
export const UPDATE_STAGE_QUESTION = "UPDATE_STAGE_QUESTION";
export const DELETE_STAGE_QUESTION = "DELETE_STAGE_QUESTION";
export const UPVOTE_STAGE_QUESTION = "UPVOTE_STAGE_QUESTION";
export const DOWNVOTE_STAGE_QUESTION = "DOWNVOTE_STAGE_QUESTION";
export const ARCHIVE_STAGE_QUESTIONS = "ARCHIVE_STAGE_QUESTIONS";
export const UPDATE_STAGE_QUESTION_STATUS = "UPDATE_STAGE_QUESTION_STATUS";

export const SET_VOTED_ON_STAGE_QUESTION_IDS =
  "SET_VOTED_ON_STAGE_QUESTION_IDS";
export const ADD_VOTED_ON_STAGE_QUESTION_ID = "ADD_VOTED_ON_STAGE_QUESTION_ID";
export const REMOVE_VOTED_ON_STAGE_QUESTION_ID =
  "REMOVE_VOTED_ON_STAGE_QUESTION_ID";

export const SET_BREAKOUT_PRESENCE_FOR_USER = "SET_BREAKOUT_PRESENCE_FOR_USER";
export const SET_BREAKOUT_PRESENCE_FOR_USERS =
  "SET_BREAKOUT_PRESENCE_FOR_USERS";

export const SET_SPEAKER_TIMER = "SET_SPEAKER_TIMER";

export const SET_CHAT_CHANNEL_NOTIFICATION = "SET_CHAT_CHANNEL_NOTIFICATION";
export const SET_QNA_NOTIFICATION = "SET_QNA_NOTIFICATION";
export const SET_PEOPLE_NOTIFICATION = "SET_PEOPLE_NOTIFICATION";
export const SET_POLL_NOTIFICATION = "SET_POLL_NOTIFICATION";

export const SET_LOUNGE_ROOMS = "SET_LOUNGE_ROOMS";
export const ADD_LOUNGE_ROOM = "ADD_LOUNGE_ROOM";
export const ADD_LOUNGE_ROOMS = "ADD_LOUNGE_ROOMS";
export const REMOVE_LOUNGE_ROOMS = "REMOVE_LOUNGE_ROOMS";
export const CLEAR_LOUNGE_ROOMS = "CLEAR_LOUNGE_ROOMS";

export const SET_LOUNGE_SESSION = "SET_LOUNGE_SESSION";
export const SET_LOUNGE_SESSION_DEFAULT_MAX_PARTICIPANTS_PER_ROOM =
  "SET_LOUNGE_SESSION_DEFAULT_MAX_PARTICIPANTS_PER_ROOM";
export const CLEAR_LOUNGE_SESSION = "CLEAR_LOUNGE_SESSION";

export const SET_CURRENT_LOUNGE_ROOM_ID = "SET_CURRENT_LOUNGE_ROOM_ID";
export const SET_CURRENT_LOUNGE_ROOM_SPECTATING =
  "SET_CURRENT_LOUNGE_ROOM_SPECTATING";

export const SET_LOUNGE_PRESENCE_FOR_ROOMS = "SET_LOUNGE_PRESENCE_FOR_ROOMS";
export const SET_LOUNGE_PRESENCE_FOR_ROOM = "SET_LOUNGE_PRESENCE_FOR_ROOM";
export const ADD_LOUNGE_PRESENCE_FOR_ROOM = "ADD_LOUNGE_PRESENCE_FOR_ROOM";
export const REMOVE_LOUNGE_PRESENCE_FOR_ROOM =
  "REMOVE_LOUNGE_PRESENCE_FOR_ROOM";

export const SET_LOUNGE_PRESENCE_BY_USER = "SET_LOUNGE_PRESENCE_BY_USER";

export const SET_PHOTOBOOTH_TEMPLATES = "SET_PHOTOBOOTH_TEMPLATES";
export const ADD_PHOTOBOOTH_TEMPLATES = "ADD_PHOTOBOOTH_TEMPLATES";
export const RENAME_PHOTOBOOTH_TEMPLATE = "RENAME_PHOTOBOOTH_TEMPLATE";
export const DELETE_PHOTOBOOTH_TEMPLATE = "DELETE_PHOTOBOOTH_TEMPLATE";

export const SET_PHOTOBOOTH_PRESENCE_FOR_USER =
  "SET_PHOTOBOOTH_PRESENCE_FOR_USER";
export const SET_PHOTOBOOTH_PRESENCE_FOR_USERS =
  "SET_PHOTOBOOTH_PRESENCE_FOR_USERS";

export const SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS";

export const ALLOW_PERMISSION_CAN_ASK_QUESTIONS =
  "ALLOW_PERMISSION_CAN_ASK_QUESTIONS";
export const DENY_PERMISSION_CAN_ASK_QUESTIONS =
  "DENY_PERMISSION_CAN_ASK_QUESTIONS";

export const ARCHIVE_POLL = "ARCHIVE_POLL";
export const CREATE_POLL = "CREATE_POLL";
export const DELETE_POLL = "DELETE_POLL";
export const SET_EDITING_POLL = "SET_EDITING_POLL";
export const SET_POLLS = "SET_POLLS";
export const UPDATE_POLL = "UPDATE_POLL";
export const VOTE_FOR_POLL = "VOTE_FOR_POLL";
export const SEARCH_FOR_POLLS = "SEARCH_FOR_POLLS";
export const START_POLL_SEARCH = "START_POLL_SEARCH";
export const STOP_POLL_SEARCH = "STOP_POLL_SEARCH";
export const SET_MOST_RECENTLY_OPENED_POLL = "SET_MOST_RECENTLY_OPENED_POLL";
