import { createConsumer } from "@anycable/web";

const cable = createConsumer({
  concurrentSubscribes: false,
  pingInterval: 10_000, // ms
});

/**
 * This is a long interval on purpose; the library will unsubscribe automatically
 * at interval * 2. We don't want this to happen because that effectively cancels
 * the subscribe request, and it may eventually go through. However, we also
 * don't want to wait forever, and may legitimately need to retry.
 *
 * The timeout here sets that to a reasonable balance. This is a private API that
 * we shouldn't fully depend on. The default value is 5000.
 */
cable.cable.protocol.subscribeRetryInterval = 15 * 1000;

export default cable;
