import {
  CLEAR_LOUNGE_SESSION,
  SET_LOUNGE_SESSION,
  SET_LOUNGE_SESSION_DEFAULT_MAX_PARTICIPANTS_PER_ROOM,
} from "~~/redux/actionList";

/** Just the lounge session */
const initialState = {};

export default (state = initialState, action) => {
  const { loungeSession, maxParticipants } = action;

  switch (action.type) {
    case CLEAR_LOUNGE_SESSION:
      return initialState;
    case SET_LOUNGE_SESSION:
      return { ...state, ...loungeSession };
    case SET_LOUNGE_SESSION_DEFAULT_MAX_PARTICIPANTS_PER_ROOM:
      return {
        ...loungeSession,
        defaultMaxParticipantsPerRoom: maxParticipants,
      };
    default:
      return state;
  }
};
