import omit from "lodash/omit";
import {
  ADD_VOTED_ON_STAGE_QUESTION_ID,
  REMOVE_VOTED_ON_STAGE_QUESTION_ID,
  SET_VOTED_ON_STAGE_QUESTION_IDS,
} from "~~/redux/actionList";

const initialState = {};

export default (state = initialState, action) => {
  const { votedOnStageQuestionIds, votedOnStageQuestionId } = action;

  switch (action.type) {
    case SET_VOTED_ON_STAGE_QUESTION_IDS:
      return votedOnStageQuestionIds;
    case ADD_VOTED_ON_STAGE_QUESTION_ID:
      return { ...state, [votedOnStageQuestionId]: true };
    case REMOVE_VOTED_ON_STAGE_QUESTION_ID:
      return omit(state, votedOnStageQuestionId);
    default:
      return state;
  }
};
