import { combineReducers } from "redux";

import BanRegistration from "~~/redux/reducers/banRegistration";
import BreakoutConfig from "~~/redux/reducers/breakoutConfig";
import BreakoutPresence from "~~/redux/reducers/breakoutPresence";
import BreakoutRoom from "~~/redux/reducers/breakoutRoom";
import BreakoutRooms from "~~/redux/reducers/breakoutRooms";
import BreakoutSession from "~~/redux/reducers/breakoutSession";
import BreakoutSessions from "~~/redux/reducers/breakoutSessions";
import DynamicEventAssets from "~~/redux/reducers/dynamicEventAssets";
import DynamicStagekitAssets from "~~/redux/reducers/dynamicStagekitAssets";
import Event from "~~/redux/reducers/event";
import EventAssets from "~~/redux/reducers/eventAssets";
import LoungeData from "~~/redux/reducers/loungeData";
import LoungePresence from "~~/redux/reducers/loungePresence";
import LoungeRooms from "~~/redux/reducers/loungeRooms";
import LoungeSession from "~~/redux/reducers/loungeSession";
import OnlineMobileUsers from "~~/redux/reducers/onlineMobileUsers";
import OnlineUsers from "~~/redux/reducers/onlineUsers";
import PanelNotifications from "~~/redux/reducers/panelNotifications";
import Permissions from "~~/redux/reducers/permissions";
import PhotoboothPresence from "~~/redux/reducers/photoBoothPresence";
import PhotoboothTemplates from "~~/redux/reducers/photoBoothTemplates";
import Polls from "~~/redux/reducers/polls";
import Registrations from "~~/redux/reducers/registrations";
import Stage from "~~/redux/reducers/stage";
import VotedOnStageQuestionIds from "~~/redux/reducers/stageQuestionVotes";
import StageQuestions from "~~/redux/reducers/stageQuestions";
import Stages from "~~/redux/reducers/stages";
import UI from "~~/redux/reducers/ui";
import User from "~~/redux/reducers/user";
import Video from "~~/redux/reducers/video";
import VideoCallStreams from "~~/redux/reducers/videoCallStreams";

const reducers = combineReducers({
  BanRegistration,
  BreakoutConfig,
  BreakoutPresence,
  BreakoutRoom,
  BreakoutRooms,
  BreakoutSession,
  BreakoutSessions,
  DynamicEventAssets,
  DynamicStagekitAssets,
  Event,
  EventAssets,
  LoungeData,
  LoungePresence,
  LoungeRooms,
  LoungeSession,
  OnlineMobileUsers,
  OnlineUsers,
  PanelNotifications,
  Permissions,
  PhotoboothPresence,
  PhotoboothTemplates,
  Polls,
  Registrations,
  Stage,
  StageQuestions,
  Stages,
  UI,
  User,
  Video,
  VideoCallStreams,
  VotedOnStageQuestionIds,
});

export default reducers;
