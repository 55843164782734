import {
  CLEAR_BREAKOUT_CONFIG,
  SET_JOIN_IMMEDIATELY,
  UPDATE_BREAKOUT_CONFIG,
} from "~~/redux/actionList";

/** Breakout config data; for example agoraChannel, tokens etc */
const initialState = {
  joinImmediately: false,
  agoraChannel: null,
  agoraRtcToken: null,
  agoraEncryptionSecret: null,
  agoraScreenshareRtcToken: null,
};

export default (state = initialState, action) => {
  const { data, joinImmediately } = action;

  switch (action.type) {
    case UPDATE_BREAKOUT_CONFIG:
      return { ...state, ...data };
    case CLEAR_BREAKOUT_CONFIG:
      return initialState;
    case SET_JOIN_IMMEDIATELY:
      return { ...state, joinImmediately };
    default:
      return state;
  }
};
