import {
  ADD_BREAKOUT_SESSION,
  REMOVE_SINGLE_BREAKOUT_SESSION,
  SET_BREAKOUT_SESSIONS,
  UPDATE_SINGLE_BREAKOUT_SESSION,
} from "~~/redux/actionList";
import { toObject } from "~~/utils/arrayUtils";

/**
 * Mapping of breakout session ID => breakout session.
 */
const initialState = {};

export default (state = initialState, action) => {
  const { breakoutSessions, breakoutSession, sessionId } = action;

  switch (action.type) {
    case SET_BREAKOUT_SESSIONS:
      return toObject(breakoutSessions, "id");
    case ADD_BREAKOUT_SESSION:
      return {
        ...state,
        [breakoutSession.id]: breakoutSession,
      };
    case UPDATE_SINGLE_BREAKOUT_SESSION:
      return {
        ...state,
        [breakoutSession.id]: {
          ...state[breakoutSession.id],
          ...breakoutSession,
        },
      };
    case REMOVE_SINGLE_BREAKOUT_SESSION:
      /* eslint-disable-next-line no-case-declarations */
      const newState = { ...state };
      delete newState[sessionId];
      return newState;
    default:
      return state;
  }
};
