/**
 * @fileoverview Local console logging for Welcome. A thin layer on top of console.*
 * methods that allow us to keep log methods consistent and create small abstractions
 * on top of default APIs.
 */
import { getNamespace } from "~~/wlog/namespace";

/**
 * Logs to the console. While we don't have typescript, WLog.LogLevel is "warn", "debug", or "info"
 *
 * @param {WLog.LogLevel} level The log level
 * @param {WLog.Namespace} namespace The namespace for the log
 * @param {string} message Message or description to be logged
 * @param  {...any} args Any additional arguments––errors, data, etc
 */
export function log(level, namespace, message, ...args) {
  const logArgs = [`[${namespace}]`, message, ...args];
  if (!getNamespace(namespace)) {
    console.error(`[WLog] Unrecognized namespace ${namespace}`);
  }

  switch (level) {
    case "warn":
      console.warn(...logArgs);
      break;
    case "debug":
      // TODO[@phil]: Look into FS.log instead of logging debug logs as info
      if (window.CLIENT_ENV.WLOG_ENABLE_DEBUG_LOGS === "true") {
        console.debug(...logArgs);
      } else {
        console.log(...logArgs);
      }
      break;
    case "info":
      console.log(...logArgs);
      break;
    default:
      console.log(...logArgs);
      console.error(`[WLog] Unsupported log level ${level}`);
  }
}
