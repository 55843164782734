import {
  ADD_BREAKOUT_ROOM,
  ADD_BREAKOUT_ROOMS,
  REMOVE_REGISTRATION_FROM_ROOM,
  REMOVE_SINGLE_BREAKOUT_ROOM,
  SET_BREAKOUT_ROOMS,
} from "~~/redux/actionList";

/** Mapping of breakoutSessionId => { breakoutRoomId => breakoutRoom } */
const initialState = {};

export default (state = initialState, action) => {
  const { sessionId, rooms, room, roomId, registrationId } = action;

  switch (action.type) {
    case SET_BREAKOUT_ROOMS:
      return { ...state, [sessionId]: rooms };
    case ADD_BREAKOUT_ROOMS:
      return {
        ...state,
        [sessionId]: { ...(state[sessionId] || {}), ...rooms },
      };
    case ADD_BREAKOUT_ROOM:
      return {
        ...state,
        [sessionId]: { ...(state[sessionId] || {}), [room.id]: room },
      };
    case REMOVE_REGISTRATION_FROM_ROOM:
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          [roomId]: {
            ...state[sessionId][roomId],
            registrations: state[sessionId][roomId].registrations.filter(
              (reg) => reg !== registrationId
            ),
          },
        },
      };
    case REMOVE_SINGLE_BREAKOUT_ROOM:
      /* eslint-disable-next-line no-case-declarations */
      const newRooms = { ...(state[sessionId] || {}) };
      delete newRooms[roomId];
      return {
        ...state,
        [sessionId]: newRooms,
      };
    default:
      return state;
  }
};
