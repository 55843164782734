/**
 * @fileoverview Event assets
 */
import {
  LOCAL_BAN_REGISTRATION,
  SET_CURRENT_REGISTRATION,
  SET_CURRENT_USER,
} from "~~/redux/actionList";

const initialState = {
  currentUser: {
    id: null,
    firstName: null,
    lastName: null,
  },
  registration: {
    id: null,
    firstName: null,
    lastName: null,
    registrationType: null,
  },
};

export default (state = initialState, action) => {
  const { user, reg } = action;

  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, currentUser: user };
    case SET_CURRENT_REGISTRATION:
      return { ...state, registration: reg };
    case LOCAL_BAN_REGISTRATION:
      return { ...state, registration: { banned: true } };
    default:
      return state;
  }
};
