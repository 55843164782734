/**
 * @fileoverview Local & remote logging library for Welcome.
 */

import { assert, assertionFailure } from "~~/wlog/assert";
import { log } from "~~/wlog/log";
import Namespace from "~~/wlog/namespace";

export default {
  log,
  assert,
  assertionFailure,
  Namespace,
};
