/**
 * @fileoverview Event assets
 */
import {
  ADD_EVENT_ASSETS,
  DELETE_EVENT_ASSET,
  SET_EVENT_ASSETS,
  UPDATE_EVENT_ASSET,
} from "~~/redux/actionList";

const initialState = {};
// Mapping of id => obj where obj contains all the relevant metadata for that event asset

export default (state = initialState, action) => {
  const { assets, asset, newAssetData } = action;

  switch (action.type) {
    case ADD_EVENT_ASSETS:
      return { ...state, ...assets };
    case SET_EVENT_ASSETS:
      return { ...assets };
    case UPDATE_EVENT_ASSET:
      return {
        ...state,
        [asset.id]: { ...(state[asset.id] || {}), ...newAssetData },
      };
    case DELETE_EVENT_ASSET:
      /* eslint-disable-next-line no-case-declarations */
      const newState = { ...state };
      delete newState[asset.id];
      return newState;
    default:
      return state;
  }
};
