import {
  SET_BREAKOUT_PRESENCE_FOR_USER,
  SET_BREAKOUT_PRESENCE_FOR_USERS,
} from "~~/redux/actionList";

/** Mapping of regId => isPresent (bool) */
const initialState = {};

export default (state = initialState, action) => {
  const { regId, isPresent, onlineIds } = action;

  switch (action.type) {
    case SET_BREAKOUT_PRESENCE_FOR_USER:
      return { ...state, [regId]: isPresent };
    case SET_BREAKOUT_PRESENCE_FOR_USERS:
      // eslint-disable-next-line no-case-declarations
      const newState = {};
      onlineIds.forEach((element) => {
        newState[element] = true;
      });
      return newState;
    default:
      return state;
  }
};
