import omit from "lodash/omit";
import {
  ADD_LOUNGE_ROOM,
  ADD_LOUNGE_ROOMS,
  CLEAR_LOUNGE_ROOMS,
  REMOVE_LOUNGE_ROOMS,
  SET_LOUNGE_ROOMS,
} from "~~/redux/actionList";
import { toObject } from "~~/utils/arrayUtils";

/** Mapping of lounge room ID => lounge room */
const initialState = {};

export default (state = initialState, action) => {
  const { loungeRooms, loungeRoom, loungeRoomIds } = action;

  switch (action.type) {
    case SET_LOUNGE_ROOMS:
      return toObject(loungeRooms, "id");
    case ADD_LOUNGE_ROOM:
      return { ...state, [loungeRoom.id]: loungeRoom };
    case ADD_LOUNGE_ROOMS:
      // Could be one room or multiple
      if (Array.isArray(loungeRooms)) {
        return { ...state, ...toObject(loungeRooms, "id") };
      }

      return { ...state, [loungeRooms.id]: loungeRooms };

    case REMOVE_LOUNGE_ROOMS:
      return omit(state, loungeRoomIds);
    case CLEAR_LOUNGE_ROOMS:
      return initialState;
    default:
      return state;
  }
};
