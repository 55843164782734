import { SET_DYNAMIC_STAGEKIT_ASSETS } from "~~/redux/actionList";

const initialState = {};

export default (state = initialState, action) => {
  const { assets } = action;

  switch (action.type) {
    case SET_DYNAMIC_STAGEKIT_ASSETS:
      return { ...assets };
    default:
      return state;
  }
};
