/* eslint-disable import/prefer-default-export */
export function toObject(arr, key) {
  return arr.reduce((acc, next) => {
    acc[next[key]] = next;
    return acc;
  }, {});
}

/** Randomize array in-place using Durstenfeld shuffle algorithm */
export function shuffleArray(_array) {
  const array = [..._array];
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

/**  returns a new array that inserts a page of items from secondaryArray every nth page of main array.
 * e.g given [1, 2, 3, 4, 5], and [6, 7, 8] and page size is 1 and alternateEveryNthPage is 1,
 * the resulting array will be[1, 6, 2, 7, 3, 8, 4, 5]
 * e.g given [1, 2, 3, 4, 5], and [6, 7, 8] and page size is 3 and alternateEveryNthPage is 1,
 * the resulting array will be [1, 2, 3, 6, 7, 8, 4, 5]
 */
export function interlaceArray(
  mainArray,
  secondaryArray,
  alternateEveryNthPage,
  pageSize
) {
  const alternateArray = [...secondaryArray];
  const sorted = [];
  for (let i = 0; i < mainArray.length; i += 1) {
    const currentPage = Math.floor(sorted.length / pageSize);
    const insertSponsoredPage = currentPage % (alternateEveryNthPage + 1);
    if (insertSponsoredPage === alternateEveryNthPage) {
      sorted.push(...alternateArray.splice(0, pageSize));
    }
    sorted.push(mainArray[i]);
  }
  return [...sorted, ...alternateArray];
}

export function areElementsEqual(arr1, arr2, callback = null) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i += 1) {
    if (callback) {
      if (!callback(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

/**
 * @param {T[]} arr
 * @param {(T, T) => number} matchFn Return -1 if object is "too high" and
 * desired item is earlier (i.e. lower-index) in the array. 1 if "too low".
 * And zero if just right.
 * @returns {[T, number]} The item and the found index
 */
export function binarySearch(arr, matchFn) {
  let startIndex = 0;
  let endIndex = arr.length - 1;

  while (startIndex <= endIndex) {
    const midIndex = Math.floor((startIndex + endIndex) / 2);
    const matchResult = matchFn(arr[midIndex], arr[midIndex + 1]);

    // Exact match
    if (matchResult === 0) {
      return [arr[midIndex], midIndex];
    }

    // Too high
    if (matchResult < 0) {
      endIndex = midIndex - 1;
    }

    if (matchResult > 0) {
      startIndex = midIndex + 1;
    }
  }

  return [undefined, -1];
}

// For use in binarySearch comparisons; e.g.
// binarySearch(arr, (item) => compareNum(item.timestamp, minVal, maxVal));
export function compareNum(num, minVal, maxVal) {
  return minVal > num ? -1 : maxVal < num ? 1 : 0;
}
