import {
  ADD_REGISTRATIONS,
  REMOVE_REGISTRATION_BREAKOUT_ROOM_ID,
  REMOVE_REGISTRATION_BREAKOUT_ROOM_IDS,
} from "~~/redux/actionList";

/** Mapping of user ID to registration */
const initialState = {};

export default (state = initialState, action) => {
  const { registrations, regId, breakoutRoomId } = action;

  switch (action.type) {
    case ADD_REGISTRATIONS:
      return { ...state, ...registrations };
    case REMOVE_REGISTRATION_BREAKOUT_ROOM_IDS:
      return Object.keys(state).reduce((obj, key) => {
        /* eslint-disable-next-line no-param-reassign */
        obj[key] = {
          ...state[key],
          breakoutRoomId: null,
          assignedBreakoutRoomIds: [],
        };
        return obj;
      }, {});
    case REMOVE_REGISTRATION_BREAKOUT_ROOM_ID:
      return {
        ...state,
        [regId]: {
          ...state[regId],
          breakoutRoomId: null,
          assignedBreakoutRoomIds: (
            state[regId].assignedBreakoutRoomIds || []
          ).filter((id) => id !== breakoutRoomId),
        },
      };
    default:
      return state;
  }
};
