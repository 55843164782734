import {
  REMOVE_BREAKOUT_SESSION,
  SET_BREAKOUT_SESSION,
  UPDATE_BREAKOUT_SESSION,
} from "~~/redux/actionList";

/** Just the breakout session */
const initialState = null;

export default (state = initialState, action) => {
  const { breakoutSession, data } = action;

  switch (action.type) {
    case SET_BREAKOUT_SESSION:
      return breakoutSession;
    case REMOVE_BREAKOUT_SESSION:
      // A completely null state means we don't know what state the breakout session is in. In this case
      // where we're removing the breakout session, we DO know this state: the breakout session has not been
      // configured (step 1). Return this object to represent that.
      return null;
    case UPDATE_BREAKOUT_SESSION:
      if (data.id == null) {
        delete data.id;
      }
      return { ...state, ...data };
    default:
      return state;
  }
};
