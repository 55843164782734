import {
  ADD_DYNAMIC_EVENT_ASSETS,
  DELETE_DYNAMIC_EVENT_ASSET,
  SET_DYNAMIC_EVENT_ASSETS,
  UPDATE_DYNAMIC_EVENT_ASSET,
} from "~~/redux/actionList";

const initialState = {};

export default (state = initialState, action) => {
  const { assets, asset, newAssetData } = action;

  switch (action.type) {
    case SET_DYNAMIC_EVENT_ASSETS:
      return { ...assets };
    case ADD_DYNAMIC_EVENT_ASSETS:
      return { ...state, ...assets };
    case UPDATE_DYNAMIC_EVENT_ASSET:
      return {
        ...state,
        [asset.id]: { ...(state[asset.id] || {}), ...newAssetData },
      };
    case DELETE_DYNAMIC_EVENT_ASSET:
      /* eslint-disable-next-line no-case-declarations */
      const newState = { ...state };
      delete newState[asset.id];
      return newState;
    default:
      return state;
  }
};
