/**
 * @fileoverview Video and RTC client management
 */
import {
  ADD_STREAM,
  REMOVE_ALL_STREAMS,
  REMOVE_CLIENT,
  REMOVE_LOCAL_STREAM,
  REMOVE_STREAM,
  SET_AGORA_RESOLVED_SCHEME,
  SET_CLIENT,
  SET_IS_INJECTION_STREAMING,
  SET_STAGE_SNAPSHOT,
  SET_STAGE_SUB_LEVEL,
  SET_STREAM,
  SET_STREAM_VIDEO_CONFIG,
  UPDATE_AGORA_CONFIG,
} from "~~/redux/actionList";
import * as FeatureFlags from "~~/utils/featureFlags";

const { releaseVp9 } = FeatureFlags.getFeatureFlags();
const codec = releaseVp9 ? "vp9" : "vp8";

const initialState = {
  // STAGE config for agora SDK
  agoraConfig: {
    uid: null,
    host: true,
    channel: "test-event-2",
    appId: window.CLIENT_ENV.AGORA_APP_ID,
    // Map of client type (primary, screen) to token
    tokens: {},
    // encryption secret
    encryptionSecret: null,
  },
  videoConfig: {
    mode: "live",
    codec: codec,
  },
  streamVideoConfigs: {},
  // All streams, local or remote
  streams: {},
  // Current layout of the stage
  stageSnapshot: {
    // Used to determine if the snapshot has been loaded form backend or not
    // Will get overridden when a new snapshot is received
    isInitialState: true,
    onStageStreams: [],
    overlayAssetId: null,
    backgroundAssetId: null,
    logoAssetId: null,
    stageScreenshareStreamId: null,
    timerEndTime: null,
    // This is the event asset id of the injected stream
    // THIS DOES NOT GET CLEARED WHEN A VIDEO STOPS PLAYING.
    // Use isInjectionStreaming to conditional on a video on stage
    injectStreamEaId: null,
    handRaisedPeople: {},
    invitedPeople: {},
    greenRoomRequestPeople: {},
    speakerTimer: null,
    noCameraUsers: [],
  },
  mutedAudioStreams: [],
  // Mapping of clientId => { client, stream }
  localClients: {},
  stageSubLevel: null,
  // whether or not an HLS or RTMP stream is being injected into our channel.
  // This gets set to false when the INJECTION_UID stops broadcasting
  isInjectionStreaming: false,
};

export default (state = initialState, action) => {
  const {
    config,
    stream,
    streamId,
    clientType,
    client,
    snapshot,
    stageSubLevel,
    isInjectionStreaming,
    encoderConfig,
    agoraResolvedScheme,
  } = action;

  switch (action.type) {
    case UPDATE_AGORA_CONFIG:
      return { ...state, agoraConfig: { ...state.agoraConfig, ...config } };
    case ADD_STREAM:
      return {
        ...state,
        streams: { ...state.streams, [stream.getId()]: stream },
      };
    case REMOVE_STREAM:
      // eslint-disable-next-line
      const newStreams = { ...state.streams };
      delete newStreams[streamId];
      return { ...state, streams: newStreams };
    case REMOVE_ALL_STREAMS:
      return { ...state, streams: {} };
    case REMOVE_CLIENT:
      // eslint-disable-next-line
      const newClients = { ...state.localClients };
      delete newClients[clientType];
      return { ...state, localClients: newClients };
    case REMOVE_LOCAL_STREAM:
      return {
        ...state,
        localClients: {
          ...state.localClients,
          [clientType]: {
            ...state.localClients[clientType],
            stream: null,
          },
        },
      };
    case SET_CLIENT:
      return {
        ...state,
        localClients: {
          ...state.localClients,
          [clientType]: { client, stream },
        },
      };
    case SET_STREAM:
      return {
        ...state,
        localClients: {
          ...state.localClients,
          [clientType]: { ...state.localClients[clientType], stream },
        },
      };
    case SET_STAGE_SNAPSHOT:
      return { ...state, stageSnapshot: snapshot };
    case SET_STAGE_SUB_LEVEL:
      return { ...state, stageSubLevel };
    case SET_IS_INJECTION_STREAMING:
      return { ...state, isInjectionStreaming };
    case SET_AGORA_RESOLVED_SCHEME:
      return { ...state, agoraResolvedScheme };
    case SET_STREAM_VIDEO_CONFIG:
      return {
        ...state,
        streamVideoConfigs: { [streamId]: encoderConfig },
      };
    default:
      return state;
  }
};
