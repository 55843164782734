/**
 * @fileoverview Methods for displaying a UI for assertion failures.
 */

function getFailureImage() {
  const urls = [
    "https://media.giphy.com/media/EimNpKJpihLY4/giphy.gif",
    "https://media.giphy.com/media/xTiTnLbo0KIJ8hrNjG/giphy.gif",
    "https://media.giphy.com/media/dJocIXW7hOQYNNY199/giphy.gif",
    "https://media.giphy.com/media/CMc604oNR1dzG/giphy.gif",
    "https://media.giphy.com/media/4BtwJzwVJBCP6/giphy.gif",
    "https://media.giphy.com/media/d2W7eZX5z62ziqdi/giphy.gif",
    "https://media.giphy.com/media/3ogmaPGsQOruw/giphy.gif",
    "https://media.giphy.com/media/l4KibWpBGWchSqCRy/giphy.gif",
    "https://media.giphy.com/media/xTiTncVep2khPGhK1i/giphy.gif",
    "https://media.giphy.com/media/gLREH1v1Z78tJckuii/giphy.gif",
  ];

  return urls[Math.floor(Math.random() * urls.length)];
}

function displayAssertionFailureUI(domId, errorMessage) {
  const style = `
    position:fixed;
    height:100%;
    width:100%;
    z-index:100000000000;
    top:0;
    left:0;
    background-color:white;
    color:black;
    padding:40px;
  `;

  const dismissFn = `
    var element = document.getElementById('${domId}');
    element.parentNode.removeChild(element);
  `;

  const imageUrl = getFailureImage();

  const html = `
    <div
      id="${domId}"
      style="${style}"
    >
      <h1>Assertion Failure</h1>
      <h2>${errorMessage}</h2>
      <p>Check the console for the full stack trace.</p>
      <button onclick="${dismissFn}" type="submit">Dismiss</button>
      <div style="margin-top:20px;">
        <img src="${imageUrl}" alt="FAIL!">
      </div>
    </div>
  `;

  document.body.innerHTML += html;
}

/**
 * Displays an error on top of all other content.
 *
 * @param {Error} error An error to display
 */
export function displayError(error) {
  const domId = Math.random().toString(36);
  displayAssertionFailureUI(domId, error.message);
}
