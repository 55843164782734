import {
  ALLOW_PERMISSION_CAN_ASK_QUESTIONS,
  DENY_PERMISSION_CAN_ASK_QUESTIONS,
} from "~~/redux/actionList";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALLOW_PERMISSION_CAN_ASK_QUESTIONS:
      return { ...state, canAskQuestions: true };
    case DENY_PERMISSION_CAN_ASK_QUESTIONS:
      return { ...state, canAskQuestions: false };
    default:
      return state;
  }
};
