import {
  CLEAR_REGISTRATION_TO_BAN,
  SET_REGISTRATION_TO_BAN,
} from "~~/redux/actionList";

const initialState = {};

export default (state = initialState, action) => {
  const { registrationToBan } = action;

  switch (action.type) {
    case SET_REGISTRATION_TO_BAN:
      return { ...state, registrationToBan };
    case CLEAR_REGISTRATION_TO_BAN:
      return { ...state, registrationToBan: null };
    default:
      return state;
  }
};
