import omit from "lodash/omit";
import {
  ADD_ONLINE_USER,
  REMOVE_ONLINE_USER,
  SET_ONLINE_USERS,
} from "~~/redux/actionList";

/**
 * Just a hashmap of user ID => true. Can think about using Immutable
 * or something.
 */
const initialState = {};

export default (state = initialState, action) => {
  const { users, uid } = action;

  switch (action.type) {
    case SET_ONLINE_USERS:
      return users.reduce((obj, id) => {
        /* eslint-disable-next-line */
        obj[id] = true;
        return obj;
      }, {});
    case ADD_ONLINE_USER:
      return { ...state, [uid]: true };
    case REMOVE_ONLINE_USER:
      return omit(state, [uid]);
    default:
      return state;
  }
};
