/**
 * @fileoverview Logging namespaces
 */

/**
 * Nested namespaces should be written as <base>.<subnamespace>. The keys should replace . with _.
 * Do not use _ in a key unless separating namespace levels. Keep this in alphabetical order.
 *
 * KEEP THIS IN ALPHABETICAL ORDER
 */
const namespaces = {
  ANALYTICS_STAFF: "analytics.staff",
  ANALYTICS_USERFEEDBACK: "analytics.userfeedback",
  BREAKOUTS: "breakouts",
  CABLE_BREAKOUTS: "cable.breakouts",
  CABLE_CAPTIONS: "cable.captions",
  CABLE_EVENTASSETS: "cable.eventassets",
  CABLE_EVENTMANAGEMENT: "cable.eventmanagement",
  CABLE_LOUNGE: "cable.lounge",
  CABLE_MULTITRACK: "cable.multitrack",
  CABLE_SESSION: "cable.session",
  CABLE_STAGE: "cable.stage",
  CABLE_STAGEQUESTION: "cable.stagequestion",
  CABLE_STAGESNAPSHOT: "cable.stagesnapshot",
  CABLE_TIMELINE: "cable.timeline",
  CABLE_VCPRESENCE: "cable.vcpresence",
  CAPTIONS: "captions",
  CONTENT_CREATOR_TRANSCRIPT_EDITOR: "contentcreator.transcripteditor",
  CHAT: "chat",
  CLIPPER: "clipper",
  CUSTOMREGPAGES: "customregpages",
  EVENTMANAGEMENT: "eventmanagement",
  EVENTSERIES: "eventseries",
  GROWTH_REFERRALS: "growth.referrals",
  MOBILE_CABLE_EVENTMANAGEMENT: "mobile.cable.eventmanagement",
  MOBILE_CABLE_STAGE: "mobile.cable.stage",
  MOBILE_CABLE_STAGEQUESTION: "mobile.cable.stagequestion",
  MOBILE_CABLE_STAGESNAPSHOT: "mobile.cable.stagesnapshot",
  MOBILE_CABLE_STAGEPRESENCE: "mobile.cable.stagepresence",
  MOBILE_CHAT: "mobile.chat",
  MOBILE_STREAMING: "mobile.streaming",
  MOBILE_STREAMING_UI: "mobile.streaming.ui",
  MOBILE_UI: "mobile.ui",
  MOBILE_UTILS: "mobile.utils",
  LOUNGE: "lounge",
  NOTIFICATIONS: "notifications",
  PEOPLE: "people",
  POLLS_SETUP: "polls.setup",
  SPEAKER_REQUESTS: "speakerrequests",
  STAGE_QUESTIONS: "stage.questions",
  STAGE_SNAPSHOTS: "stage.stagesnapshot",
  TIMELINE: "timeline",
  TIMELINE_UPLOAD: "timeline.upload",
  UI: "ui",
  UI_FRONTDOOR: "ui.frontdoor",
  UI_INTERACTIONPANEL: "ui.interactionpanel",
  UI_CHAT: "ui.chat",
  WELCOMEAV: "welcomeav",
  WELCOMEAV_AUDIO: "welcomeav.audio",
  WELCOMEAV_CAPTURE: "welcomeav.capture",
  WELCOMEAV_STREAMING: "welcomeav.streaming",
  WELCOMEAV_STREAMING_RTMP: "welcomeav.streaming.rtmp",
  WELCOMEAV_RENDER: "welcomeav.render",
  WELCOMEAV_NETWORKTEST: "welcomeav.networktest",
  WELCOMEAV_VIRTUALBACKGROUND: "welcomeav.virtualbackground",
  VIDEO: "video",
  VIDEO_CALLING: "video.calling",
  VIDEO_USERECENTSPEAKERS: "video.userecentspeakers",
  VIDEO_ONEONONE: "video.oneonone",
  ACTIONCABLE_MESSAGE: "actioncable.message",
  ACTIONCABLE_DISCONNECT: "actioncable.disconnect",
};

const reverseMapping = {};
Object.entries(namespaces).forEach(([key, namespace]) => {
  reverseMapping[namespace] = key;
});

/**
 * @param {string} rawString The raw namespace value, e.g. "welcomeav.audio"
 */
export function getNamespace(rawString) {
  return reverseMapping[rawString];
}

export default namespaces;
